export const ROLE_MAP = {
  0: "Admin",
  1: "Terminal Logistics",
  2: "Terminal Control Room",
  3: "Terminal Team Leader",
  4: "Company Admin",
  5: "Customer User",
  6: "Surveyor Admin",
  7: "Surveyor",
  8: "Barge",
  9: "Barge Operator",
  10: "Terminal Pilot" ,
  11: "Terminal Loading Master",
};
export const  JETTY_LIST= {
  "3": [],
  "5": [], 
  "6": [],
  "7": [],
  "8": [],
  "9": [],
  "10": [],
  "11": [],
  "13": [],
  "15": [],
  "16": [],
  "17": [],
};
// for test
export const mockCompanyList =[
  {companyName:"0 Oil Marine Pte. Ltd.",num:2},
  {companyName:"0 Petro Private Limited",num:3},
  {companyName:"0 Champion Pte. Ltd.",num:4},
  {companyName:"1 Oil Marine Pte. Ltd.",num:2},
  {companyName:"1 Petro Private Limited",num:3},
  {companyName:"1 Champion Pte. Ltd.",num:4},
 ];

 export const jettyHoseList =[
  { jettyNo: 3, hoses: ["LA35", "LA36"] },
  { jettyNo: 5, hoses: ["BH51", "BH52"] },
  { jettyNo: 6, hoses: ["BH61", "BH62", "WH61"] },
  { jettyNo: 7, hoses: ["BH71", "BH72"] },
  { jettyNo: 8, hoses: ["BH81", "BH82"] },
  { jettyNo: 9, hoses: ["BH91", "BH92", "WH92"] },
  { jettyNo: 10, hoses: ["BH101", "BH102"] },
  { jettyNo: 11, hoses: ["LA114"] },
  { jettyNo: 13, hoses: ["LA134"] },
  { jettyNo: 15, hoses: ["BH151","WH151"] },
  { jettyNo: 16, hoses: ["BH161","WH161"] },
  { jettyNo: 17, hoses: ["BH171","WH171"] },
 ];
