<template>
  <div id="app" @click="detectMotion" @mousemove="detectMotion" @keydown="detectMotion">
    <router-view></router-view>
    <KeepAlive>
      <Version ref="version"></Version>
    </KeepAlive>
    <KeepAlive>
      <Inactivity ref="inactivity"></Inactivity>
    </KeepAlive>
  </div>
</template>

<script>
import Inactivity from "@/view/inactivity.vue";
import Version from "@/view/version.vue";

export default {
  name: "App",
  components: { Version, Inactivity },
  data() {
    return {
    }
  },
  created() {
    // this.$store.dispatch("getUserInfo");
  },
  methods: {
    detectMotion() {
      this.$refs.inactivity.refresh();
      this.$refs.version.verify();
    }
  }
};
</script>

<style>
#app {
  width: 100vw;
  height: 100vh;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  width: 5px;
  background: rgba(123, 123, 123, 0.3);
  border-radius: 10px;
}
.el-dialog {
  border-radius: 10px !important;
}
.el-table {
  width: 99.9% !important;
}
/* html {
  overflow-y: scroll;
  overflow-x: hidden;
} */
.el-table__row > .el-table__cell > .cell {
  /* font-size: 15px; */
  font-weight: 500;
  color: #4c565c;
}
.el-select__tags-text, .el-input.is-disabled .el-input__inner {
  color: #4C565C !important;
}
</style>
